/**
 * The info associated with this file is intended to be accessed in the console for debugging, testing, and developing.
 * It is NOT intended to be coded against.
 */

import type { WebShellClientInfo } from "@nike/web-shell-types";
import { DEFAULT_WEB_SHELL_CLIENT_INFO } from "@nike/web-shell-shared";

// should never be exported, mutate with functions. get with getWebShellClientInfo
let webShellClientInfo: WebShellClientInfo = {
  ...DEFAULT_WEB_SHELL_CLIENT_INFO
};

export const getWebShellClientInfo = (): WebShellClientInfo => webShellClientInfo;

/**
 * Functions that reassign to webShellClientInfo
 */
function reset(): void {
  webShellClientInfo = { ...DEFAULT_WEB_SHELL_CLIENT_INFO };
}

function initializeIdentity(userManager: `accounts`): void {
  webShellClientInfo = {
    ...webShellClientInfo,
    identityProvider: userManager
  };
}

export const webShellClientInfoDispatch = {
  reset,
  initializeIdentity
};
