import Cookies from "js-cookie";
import type {
  UserLocationData,
  WebShellUserLocation,
  UserLocationKeys
} from "@nike/web-shell-types";
import { DEFAULT_USER_LOCATION_DATA } from "@nike/web-shell-shared";

export const GEOLOC_COOKIE_NAME = `geoloc`;
const geolocKeysMap: Record<string, UserLocationKeys> = {
  cc: `country`,
  rc: `state`,
  la: `latitude`,
  lo: `longitude`
};

export class DotcomWebShellUserLocation implements WebShellUserLocation {
  current: UserLocationData;

  /**
   * @deprecated Use DEFAULT_USER_LOCATION_DATA from "@nike/web-shell-types" instead
   */
  static defaultUserLocation: UserLocationData = { ...DEFAULT_USER_LOCATION_DATA };

  constructor() {
    this.current = this.readCookie();
  }

  readCookie = (): UserLocationData => {
    const userLocation: UserLocationData = {
      ...DEFAULT_USER_LOCATION_DATA
    };
    if (typeof document === `undefined`) {
      return userLocation;
    }
    const userData =
      Cookies.get(GEOLOC_COOKIE_NAME)
        ?.split(`,`)
        .reduce((result, keyValue) => {
          const [key, value] = keyValue.split(`=`);
          result[key] = value;
          return result;
        }, {}) ?? {};
    for (const [key, value] of Object.entries(geolocKeysMap)) {
      userLocation[value] = userData[key] ?? ``;
    }
    return userLocation;
  };

  get = (): UserLocationData => this.current;
}
