import type {
  GetLocationsArgument,
  GetLocationsResult,
  GetOffersOptions,
  GetOffersResponse,
  Location,
  LocationResult
} from "@nike/web-shell-types";
import { WebShellOptimization } from "@nike/web-shell-types";

export class DotcomWebShellAdobeTargetOptimization extends WebShellOptimization {
  constructor(locations?: Location[]) {
    super(locations);

    window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_OPTIMIZATION_INITIALIZED`, {
      webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``
    });
  }

  public getLocations = async (
    getLocationsArgument?: GetLocationsArgument
  ): Promise<GetLocationsResult | undefined> => {
    if (!window.adobe?.target) {
      // eslint-disable-next-line no-console
      console.warn(
        `Optimization is unable to find Adobe Target. Please check that your application is configured correctly.`
      );
      return;
    }

    const currentLocations = getLocationsArgument?.locations ?? this._locations;

    if (currentLocations.length === 0) {
      // eslint-disable-next-line no-console
      console.error(
        `Locations must be provided either via a function argument or as a part of the Optimization configuration`
      );
      return;
    }

    try {
      const profile = await this.getUserProfile();
      const offerOptions = this.getOptions(currentLocations, profile);

      await window.analyticsClient.isInitialized();

      const permissions = window.NikePrivacy.permissions
        .get()
        ?.map(({ category, isEnabled }) => (isEnabled ? category : null))
        .filter(Boolean);

      const UGP_BEHAVIORAL_ADS = `adtargeting_behavioralevents`;
      const UGP_PERFORMANCE_ANALYTICS = `performance`;

      const hasConsent = [UGP_BEHAVIORAL_ADS, UGP_PERFORMANCE_ANALYTICS].every((permission) =>
        permissions?.includes(permission)
      );

      if (!hasConsent) {
        // eslint-disable-next-line no-console
        console.warn(
          `Appropriate UGP permissions have not been given. Cannot fetch Adobe Target locations.`
        );
        return;
      }

      const offers = await window.adobe.target.getOffers(offerOptions);

      return this.transformAdobeOffersToGetLocationsResult(offers);
    } catch (error: unknown) {
      if (window.newrelic?.noticeError) {
        window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_OPTIMIZATION_ERROR`, {
          webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``,
          host: window.location.host,
          errorName: (error as Error).name,
          errorMessage: (error as Error).message,
          stackTrace: (error as Error).stack ?? ``
        });
      }

      // eslint-disable-next-line no-console
      console.error(`Failed to find locations: ${(error as Error).message}`);
    }
  };

  private getUserProfile = async (): Promise<
    { userId: string | undefined; loggedIn: boolean } | undefined
  > => {
    const profile = await window.webShellClient.identity.getUserProfile();
    return {
      userId: profile?.upmId,
      loggedIn: !!profile?.upmId
    };
  };

  private getOptions = (
    locations: Location[],
    profile: { userId: string | undefined; loggedIn: boolean } | undefined
  ): GetOffersOptions => ({
    request: {
      context: {
        channel: `web`
      },
      experienceCloud: {
        analytics: {
          logging: `client_side`
        }
      },
      property: {
        token: `ae0ecea9-920a-26c9-5042-3a3aab0ff68b`
      },
      execute: {
        mboxes: locations.map((location, index) => ({
          name: location.name,
          index,
          parameters: location.parameters,
          profileParameters: profile
        }))
      }
    }
  });

  private transformAdobeOffersToGetLocationsResult = (
    offers: GetOffersResponse
  ): GetLocationsResult | undefined => {
    const locations: LocationResult[] | undefined = offers.execute?.mboxes.map(
      (mbox): LocationResult => ({
        name: mbox.name!,
        content: mbox.options?.[0].content,
        analytics: mbox.analytics?.payload.tnta
      })
    );

    if (locations && locations.length > 0) {
      return {
        locations
      };
    }
  };
}
