import type {
  WebShellLocale,
  WebShellClient,
  WebShellTranslations,
  WebShellWindow,
  WebShellUserLocation,
  WebShellIdentity
} from "@nike/web-shell-types";
import { WebShellClientFactory, defaultWebShellClientOptions } from "./factory";

export * from "./locale";
export * from "./userLocation";

export { ModalManager } from "./modals";

const options =
  (((global as unknown) as WebShellWindow | undefined) ?? window).__shell ??
  defaultWebShellClientOptions;

export type Locale = WebShellLocale;

export type Translations = WebShellTranslations;

export type WebShellClientWindow = WebShellWindow;

export type UserLocation = WebShellUserLocation;

export type Identity = WebShellIdentity;
export const factory = WebShellClientFactory;

const instance: WebShellClient = WebShellClientFactory.getInstance(options);

export const {
  analytics,
  appName,
  identity,
  locale,
  modalManager,
  optimization,
  translations,
  userLocation,
  __VERSION__,
  __WS_INTERNAL__
} = instance;

// For backwards compatibility before we moved to named exports
/* eslint-disable import/no-default-export */
/** @deprecated
 *  Use `import { instance } from "@nike/web-shell-client"`
 *  instead as all default exports are slated for removal
 * */
export default instance;
