import type {
  DataCaptureAnalyticsClient,
  WebShellAnalyticsStub,
  WebShellAnalyticsOptions
} from "@nike/web-shell-types";

export const PROD_SEGMENT_WRITE_KEY = `2iv4Qa7rFEipAs4iE850BkBpTYQvlAFZ`;

// TODO: Remove any writeKey references.
export const defaultAnalyticsOptions: WebShellAnalyticsOptions = {
  writeKey: PROD_SEGMENT_WRITE_KEY
};

/**
 * Provide a stub of analytics client in case the script doesn't load.
 * Calling the analytics client methods will issue warnings.
 */
export function dotcomNoopAnalyticsFactory(): WebShellAnalyticsStub {
  function stub(functionName: string): (...args: any[]) => void {
    return (...args: any[]): void => {
      const errorMessage = `Web Shell Client Analytics: analytics.${functionName}() called but no analytics client is configured.  Please supply window.analyticsClient to use Web Shell's analytics feature`;

      /* eslint-disable no-console */
      console.warn(errorMessage);
      console.log(`.${functionName}() called with: `, args);
      /* eslint-enable */

      if (window.newrelic?.noticeError) {
        window.newrelic.noticeError(errorMessage);
      }
    };
  }
  return {
    load: stub(`load`),
    debug: stub(`debug`),
    track: stub(`track`),
    page: stub(`page`)
  };
}

export function dotcomWebShellAnalyticsFactory(
  options: WebShellAnalyticsOptions | undefined
): DataCaptureAnalyticsClient | WebShellAnalyticsStub {
  const analyticsClientStub = dotcomNoopAnalyticsFactory();

  if (typeof window === `undefined` || !window.analyticsClient) {
    return analyticsClientStub;
  }

  // TODO: Deprecate the load method entirely.
  window.analyticsClient.load(options);
  window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_ANALYTICS_INITIALIZED`, {
    webShellClientVersion: process.env.WEB_SHELL_CLIENT_VERSION ?? ``
  });

  // We are providing alias directly to analytics client.
  // This way we can de-couple web shell client from analytics client.
  return window.analyticsClient as DataCaptureAnalyticsClient;
}
