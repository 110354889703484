import Cookies from "js-cookie";
import type { UserProfile, WebShellIdentity } from "@nike/web-shell-types";
import type {
  User,
  Profile,
  UserManager as UserManagerType,
  WebStorageStateStore as WebStorageStateStoreType
} from "@nike/oidc-client";
import { HOST_NAME } from "@nike/web-shell-shared";
import { removeVisitData } from "./storage";
import { webShellClientInfoDispatch } from "../internal/webShellClientInfo";
import { addPageAction } from "../newRelicUtils";
import { GuestSession } from "./guestSession";

type UserManager = { type: null; instance: null } | { type: `oidc`; instance: UserManagerType };

const isSwooshUser = (
  profile: Partial<{ userType?: string; swoosh?: boolean }> | undefined
): boolean => {
  if (profile?.userType === `EMPLOYEE` || profile?.swoosh) {
    return true;
  }
  return false;
};

// input data is either user.profile from oid userManager's .getUser or profile object from success callback of session.getUserProfile
const shapeUserProfile = (data: any): UserProfile => {
  const profile: UserProfile = {
    avatarUrl:
      data?.picture || data?.avatar_url || data?.entity?.avatar?.fullUrl || data?.avatarUrl,
    firstName: data?.given_name || data?.entity?.firstName || data?.firstName,
    altFirstName:
      data?.name?.kana?.given ||
      data?.name?.alternate?.given ||
      data?.entity?.jpFirstNameKana ||
      data?.altFirstName,
    altLastName:
      data?.name?.kana?.family ||
      data?.name?.alternate?.family ||
      data?.entity?.jpLastNameKana ||
      data?.altLastName,
    lastName: data?.family_name || data?.entity?.lastName || data?.lastName,
    email: data?.email || data?.entity?.account?.email,
    mobileNumber: data?.phone_number || data?.entity?.mobileNumber || data?.mobileNumber,
    upmId: data?.sub || data?.entity?.account?.id || data?.upmId,
    registeredCountry: data?.country,
    signInFlow: data?.flow,
    userType: `GUEST`
  };
  if (isSwooshUser(data)) {
    profile.userType = `SWOOSH`;
  } else if (profile.upmId) {
    profile.userType = `MEMBER`;
  }
  return profile;
};

///////////////////////////////
// OidcClient config
///////////////////////////////
// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.INFO;

const callBackRouteRegex = /^(?:\/auth\/(?:login|logout|silent-renew))|^(?:\/(?:[a-z]{2}\/(?:[a-z]{2}(?:-[A-z]{4})?\/)?)?(?:login|register))/;
const isNotACallbackRoute = (): boolean =>
  callBackRouteRegex.exec(window.location.pathname) === null;
function createOIDCSettings(userStore: WebStorageStateStoreType): Object {
  return {
    authority: process.env.NEXT_PUBLIC_AUTHORITY,
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    redirect_uri: process.env.NEXT_PUBLIC_REDIRECT_URI ?? `${window.location.origin}/auth/login`,
    post_logout_redirect_uri:
      process.env.NEXT_PUBLIC_POST_LOGOUT_REDIRECT_URI ?? `${window.location.origin}/auth/logout`,
    silent_redirect_uri:
      process.env.NEXT_PUBLIC_SILENT_REDIRECT_URI ?? `${window.location.origin}/auth/silent-renew`,
    response_type: `code`,
    scope: `openid nike.digital profile email phone flow country`,
    loadUserInfo: false,
    automaticSilentRenew: true,
    userStore,
    monitorSession: isNotACallbackRoute()
  };
}

export const IS_SIGNED_IN_COOKIE_NAME = `ni_s`;

const setIsSignedInCookie = (domain: string): void => {
  Cookies.set(IS_SIGNED_IN_COOKIE_NAME, `1`, { domain, path: `/`, expires: 365 });
};

const removeIsSignedInCookie = (domain: string): void => {
  Cookies.remove(IS_SIGNED_IN_COOKIE_NAME, { domain, path: `/` });
};

export const hasIsSignedInCookie = (): boolean => Cookies.get(IS_SIGNED_IN_COOKIE_NAME) === `1`;

const isCrossSubdomainNavigation = (): boolean =>
  document.referrer.includes(`nike`) && !document.referrer.includes(document.location.origin);

const shouldSilentlySignIn = (user: User | null | undefined): boolean =>
  Boolean(
    ((isCrossSubdomainNavigation() && hasIsSignedInCookie()) || user?.expired) &&
      isNotACallbackRoute()
  );

/** @description Clears OIDC Client identity data from the browser */
const clearOidcDataForSubdomains = async (userManager: UserManager): Promise<void> => {
  await userManager.instance.removeUser();
};

export function makeIdentity(): WebShellIdentity {
  const gs = new GuestSession();
  const domain = HOST_NAME === `www.nike.com` ? `nike.com` : `nike.com.cn`;

  const _init = async (): Promise<UserManager> => {
    let userManager: UserManager = { type: null, instance: null };
    const webShellClientVersion = process.env.WEB_SHELL_CLIENT_VERSION ?? ``;

    const isClient = typeof window !== `undefined`;

    try {
      if (isClient) {
        let signedInCookieIsPresent = hasIsSignedInCookie();

        window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_IDENTITY_INITIALIZED`, {
          webShellClientVersion
        });

        // dynamically import oidc client
        const { UserManager, WebStorageStateStore } = await import(`@nike/oidc-client`);

        const userStore = new WebStorageStateStore({ store: window.localStorage });
        const OIDCSettings = createOIDCSettings(userStore);
        userManager = { type: `oidc`, instance: new UserManager(OIDCSettings) };
        // If the user is not signed in, we clear the OIDC Client data from the browser to ensure subdomains are signed out:
        if (!signedInCookieIsPresent) await clearOidcDataForSubdomains(userManager);

        // removed accounts visitData implementation (PR: https://github.com/nike-internal/dotcom.web-shell/pull/576)
        // use silent-renew feature if appropriate
        const user = await userManager.instance.getUser();
        if (shouldSilentlySignIn(user)) {
          await userManager.instance.signinSilent();
        }

        // When a user returns to a Nike webpage: check if the "ni_s" (sign in) cookie has changed
        // If so, send the "nikeSignOut" event to teams consuming Web Shell Client
        const createSignOutEventForwarder = (): void => {
          const dispatchSignOutOnCookieRemoved = (): void => {
            // We check 'signedInCookie' is present to ensure the signOut event only fires on sign out
            // Otherwise, this event would fire each time the window is focused
            if (signedInCookieIsPresent && !hasIsSignedInCookie()) {
              signedInCookieIsPresent = false;
              window.dispatchEvent(new Event(`nikeSignOut`));
            }
          };
          // The focus event checks: when a window is focused
          window.addEventListener(`focus`, dispatchSignOutOnCookieRemoved);
          // The visibility change event checks: when a tab is selected or window is un-minimized
          window.addEventListener(`visibilitychange`, () => {
            if (document.visibilityState === `visible`) dispatchSignOutOnCookieRemoved();
          });
        };
        createSignOutEventForwarder();

        // putting intializeVisitData here b/c the tests were erroring out for getInitialized
        gs.initializeVisitData();
      }

      webShellClientInfoDispatch.initializeIdentity(`accounts`);
    } catch {
      window.newrelic?.addPageAction(`WEB_SHELL_CLIENT_IDENTITY_INITIALIZE_ERROR`, {
        webShellClientVersion
      });
    }

    return userManager;
  };

  const userManagerPromise = _init();

  /**
   * This function is only used by the OIDC callback route
   * and should NOT be used directly
   */
  const processSignIn: WebShellIdentity[`processSignIn`] = async () => {
    try {
      const userManager = await userManagerPromise;
      const user = await userManager.instance?.signinCallback();
      setIsSignedInCookie(domain);
      return user;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_SIGN_IN_ERROR`);
      throw e;
    }
  };

  const processSignOut: WebShellIdentity[`processSignOut`] = async () => {
    try {
      const userManager = await userManagerPromise;
      const response = await userManager.instance?.signoutCallback();
      removeVisitData();
      window.dispatchEvent(new Event(`nikeSignOut`));
      return response;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_SIGN_OUT_ERROR`);
      throw e;
    }
  };

  const getUser: WebShellIdentity[`getUser`] = async () => {
    const userManager = await userManagerPromise;
    const user = await userManager.instance?.getUser();
    return user;
  };

  const getUserProfile: WebShellIdentity[`getUserProfile`] = async () => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_USER_PROFILE`);
      const user = await getUser();
      if (user) {
        return shapeUserProfile(user.profile);
      }
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_USER_PROFILE_ERROR`);
      throw e;
    }
  };

  const getIsMobileVerified: WebShellIdentity[`getIsMobileVerified`] = async () => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_MOBILE_VERIFIED`);
      const user = await getUser();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return (user?.profile as Profile | undefined)?.phone_number_verified === true;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_MOBILE_VERIFIED_ERROR`);
      throw e;
    }
  };

  const getIsSwooshUser: WebShellIdentity[`getIsSwooshUser`] = async () => {
    try {
      const user: { profile?: Object } | null | undefined = await getUser();
      const isSwoosh = isSwooshUser(user?.profile);
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_SWOOSH_USER`, {
        isSwoosh
      });
      return isSwoosh;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_IS_SWOOSH_USER_ERROR`);
      throw e;
    }
  };

  // Modified to take into account of guest session iframe
  const getVisitData: WebShellIdentity[`getVisitData`] = async () => {
    try {
      if (window.nike?.unite?.isInitialized) {
        // For China guest sessions
        return window.nike.unite.session.getVisitData();
      }
      return await gs.getVisitData();
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_VISIT_DATA_ERROR`);
      throw e;
    }
  };

  const signIn: WebShellIdentity[`signIn`] = async (args = {}) => {
    const userManager = await userManagerPromise;
    try {
      const currentUrl = window.location.href;
      const state = {
        ...args,
        redirectUrl: currentUrl
      };
      return userManager.instance?.signinRedirect({ ...args, state });
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_IN_ERROR`);
      throw e;
    }
  };

  const signOut: WebShellIdentity[`signOut`] = async (args = {}) => {
    try {
      if (window.nike?.unite?.isInitialized) {
        // For China guest sessions
        window.nike.unite.session.logout();
      } else {
        gs.resetVisitData();
      }
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_OUT`);
      const state = {
        ...args,
        redirectUrl: window.location.href
      };

      removeIsSignedInCookie(domain);

      const userManager = await userManagerPromise;
      window.dispatchEvent(new Event(`nikeSignOut`));
      return userManager.instance?.signoutRedirect({ ...args, state });
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_SIGN_OUT_ERROR`);
      throw e;
    }
  };

  const getIsLoggedIn: WebShellIdentity[`getIsLoggedIn`] = async () => {
    const profile = await getUserProfile();

    if (profile) {
      return profile.userType !== `GUEST`;
    }

    return !!profile;
  };

  /**
   * @returns {Promise<string|undefined>} access token if it exists
   */
  const getAccessToken: WebShellIdentity[`getAccessToken`] = async () => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_ACCESS_TOKEN`);

      // Must ensure that silent-renew is finished before getting token.
      const user = await getUser();

      if (user) {
        return user.access_token;
      }
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_ACCESS_TOKEN_ERROR`);
      throw e;
    }
  };

  const getInitialized: WebShellIdentity[`getInitialized`] = async () => {
    try {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_INITIALIZED`);
      const userManager = await userManagerPromise;
      return !!userManager;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GET_INITIALIZED_ERROR`);
      throw e;
    }
  };

  /**
   * This function is used ONLY internally by the OIDC callback route
   * (i.e. www.nike.com/auth/silent-renew) and should NOT be used
   * directly
   * @returns
   */
  const processReauth: WebShellIdentity[`processReauth`] = async () => {
    try {
      const userManager = await userManagerPromise;

      if (userManager.type !== `oidc`) {
        return;
      }

      const user = await userManager.instance.signinSilentCallback();
      if (user) {
        setIsSignedInCookie(domain);
      }
      return user;
    } catch (e: unknown) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_PROCESS_REAUTH_ERROR`);
      throw e;
    }
  };

  const identityObject: WebShellIdentity = {
    /**
     * @deprecated identity module is now self-initializing,
     * this method call can be safely removed
     */
    initialize: async () => {
      await userManagerPromise;
      return identityObject;
    },
    getAccessToken,
    getInitialized,
    getIsLoggedIn,
    getIsMobileVerified,
    getIsSwooshUser,
    getUser,
    getUserProfile,
    getVisitData,
    processReauth,
    processSignIn,
    processSignOut,
    signIn,
    signOut
  };
  return identityObject;
}
