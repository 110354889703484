import { v4 as uuid } from "uuid";
import { addPageAction } from "../newRelicUtils";

export interface VisitData {
  visitId: number;
  visitorId: string;
}

export interface GetVisitData {
  visit: number;
  visitor: string;
  visitId: number;
  visitorId: string;
}

export interface GSEvent {
  origin: string;
  data:
    | { type: `setVisitData` | `visitDataReady`; visitData: VisitData }
    | { type: `gsIframeReady` } // The GSM iframe is ready
    | { type: `generateVisitData` };
}

const allowedOrigins = [
  `https://www.nike.com`,
  `https://localhost.nike.com`,
  `https://gs-checkout.nike.com`,
  `https://gs.nike.com`,
  `https://gs-profilemanagement.nike.com`,
  `https://www.nike.com.cn`,
  `https://uat.nike.com`
] as const;

export const isSafeOrigin = (
  origin: string
  // typed to avoid confusing false eslint warnings about unnecessary conditions where this function is used
): origin is typeof allowedOrigins[number] =>
  (allowedOrigins as readonly string[]).includes(origin);

export class GuestSession {
  private _visitDataIsResolved: Boolean = false;
  private _gsIframeElement: HTMLIFrameElement | null = null;
  private _visitData: VisitData = {
    visitId: 0,
    visitorId: ``
  };

  private get _guestSessionIframe(): HTMLIFrameElement | null {
    const iframeId = `guest-session-iframe`;
    if (!this._gsIframeElement) {
      // it is possible that this WSC GSM code runs before the iframe is ready
      // in this scenario, the iframe will send the 'gsIframeReady' event to let GSM know when to continue
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement | null;
      this._gsIframeElement = iframe;
    }
    return this._gsIframeElement!;
  }

  /**
   * @description Handles Guest Session logout on adjacent nike.com window/tab: by adding an event listener
   * attached to localStorage, we can update the key 'wsc_user_logged_out' within one tab to trigger
   * listeners within other tabs/windows to fetch the updated Guest Session data
   */
  private _addResetVisitDataListener(): void {
    window.addEventListener(`storage`, (event) => {
      if (event.key === `wsc_user_logged_out` && event.newValue === `true`) {
        this._postGetVisitDataMessageToGS();
        window.localStorage.removeItem(`wsc_user_logged_out`);
      }
    });
  }

  /**
   * @description Initializes the Guest Session Manager.
   * Fetches Guest Session data and adds the event listeners that detect changes to the session
   */
  public initializeVisitData = (): void => {
    window.addEventListener(`message`, this._handleMessageEvent);
    this._addResetVisitDataListener();

    // This covers the scenario where the Iframe loads and fires the 'gsIframeReady' event
    // before we are ready to capture it within our message handler.
    this._postGetVisitDataMessageToGS();
  };

  /**
   * @description Handles messages sent from the GSM iframe
   */
  private _handleMessageEvent = (event: GSEvent): void => {
    if (!isSafeOrigin(event.origin)) return;

    switch (event.data.type) {
      // When the iframe is ready, it will send the 'gsIframeReady' message which gathers the GSM data
      case `gsIframeReady`:
        this._postGetVisitDataMessageToGS();
        break;
      case `setVisitData`:
        this._setVisitData(event.data.visitData);
        break;
      case `generateVisitData`:
        this._generateVisitData();
        break;
      default:
        break;
    }
  };

  /**
   * @description Called on this.init() and when iframe becomes ready--triggers the iframe to respond
   * back with 'setVisitData' (sets existing session data) or 'generateVisitData' (create new session)
   */
  private _postGetVisitDataMessageToGS = (): void => {
    const gsIframe = this._guestSessionIframe;
    if (!gsIframe) return;
    gsIframe.contentWindow?.postMessage(
      {
        type: `getVisitData`
      },
      `https://${process.env.NEXT_PUBLIC_HOST_NAME}`
    );
  };

  /**
   * @description Sets visit data in memory and posts message to resolve 'getVisitData' promise
   */
  private _setVisitData = (newVisitData: VisitData): void => {
    // In order to ensure we are able to resolve the getVisitData function as well as take the
    // race condition into consideration (sometimes the iframe is not ready when we postMessage with type `getVisitData`),
    // we check if the incoming visit data is the same as the exisiting one in memory because we don't want to unnecessarily
    // fire another visitDataReady message event
    if (
      this._visitData.visitId === newVisitData.visitId &&
      this._visitData.visitorId === newVisitData.visitorId
    ) {
      return;
    }
    addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_SET_VISIT_DATA`);
    this._visitData = newVisitData;

    // post message to self in order for getVisitData promise to resolve with the visit data, we only fire this postMessage
    // if getVisitData promise has not resolved
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this._visitDataIsResolved) {
      window.postMessage(
        {
          type: `visitDataReady`,
          visitData: newVisitData
        },
        location.origin
      );
    }
    this._visitDataIsResolved = true;
  };

  private _generateNewVisitData = (): VisitData => ({ visitId: 1, visitorId: uuid() });

  private _generateVisitData = () => {
    const gsIframe = this._guestSessionIframe;
    if (!gsIframe) {
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GENERATE_IFRAME_NOT_FOUND_ERROR`);
      return;
    }

    addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GENERATE_VISIT_DATA`);

    const visitData = this._generateNewVisitData();

    gsIframe.contentWindow?.postMessage(
      {
        type: `setVisitData`,
        visitData
      },
      `https://${process.env.NEXT_PUBLIC_HOST_NAME}`
    );
    this._setVisitData(visitData);
  };

  private _getVisitDataError = (): void => {
    if (this._guestSessionIframe) {
      if (isSafeOrigin(window.location.origin)) {
        addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GET_VISIT_DATA_UNRESOLVED_ERROR`);
      } else {
        addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GET_VISIT_DATA_UNSAFE_ORIGIN_ERROR`);
        throw Error(`"getVisitData" could not resolve - origin not whitelisted.`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `"getVisitData" could not resolve - No guest session iframe found by "guest-session-iframe" id`
      );
      addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GET_VISIT_DATA_IFRAME_NOT_FOUND_ERROR`);
    }
  };

  /**
   * @description Returns a promise containing the Guest Session data. Should be called frequently to
   * ensure session data is up to date.
   */
  public getVisitData = async (): Promise<GetVisitData> => {
    addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_GET_VISIT_DATA`);
    // If 'getVisitData' is called before the iframe has responded with the session info,
    // we add the listener below and resolve once we have the Guest Session data ready
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this._visitDataIsResolved) {
      // This timeout will send a "not found" event to New Relic after 2 seconds
      // If the event listener receives the data before 2 seconds, it will not send the event (clear the timeout)
      const noIframeFoundTimeout = setTimeout(this._getVisitDataError, 2000);
      return new Promise((resolve) => {
        window.addEventListener(`message`, (event: GSEvent): void => {
          if (isSafeOrigin(event.origin) && event.data.type === `visitDataReady`) {
            clearTimeout(noIframeFoundTimeout);
            this._setVisitData(event.data.visitData);
            resolve({
              visitId: event.data.visitData.visitId,
              visit: event.data.visitData.visitId,
              visitorId: event.data.visitData.visitorId,
              visitor: event.data.visitData.visitorId
            });
          }
        });
      });
    }
    return {
      visitId: this._visitData.visitId,
      visit: this._visitData.visitId,
      visitor: this._visitData.visitorId,
      visitorId: this._visitData.visitorId
    };
  };

  /**
   * @description Clears the current guest session across all nike.com windows/tabs and creates a new one
   */
  public resetVisitData = (): void => {
    addPageAction(`WEB_SHELL_CLIENT_IDENTITY_GSM_RESET_VISIT_DATA`);
    this._generateVisitData();

    // We set a short timeout to allow the iframe time to receive the new visit data
    setTimeout(() => {
      // When 'wsc_user_logged_out' is set in local storage, the reset listener across other nike.com windows
      // will catch the event and fetch the new Guest Session data
      window.localStorage.setItem(`wsc_user_logged_out`, `true`);
    }, 10);
  };
}
